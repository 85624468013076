import React, { useState } from "react";
import * as ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { store, history } from "./app/store";
import { Provider, useSelector } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import * as serviceWorker from "./serviceWorker";
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { initAppInsights, reactPlugin } from "./common/appInsights/AppInsights";
import { getSelectedRegion } from "features/region/selectors";
import { auth } from "common/auth";
import Spin from "antd/lib/spin";

export const AppWithAppInsights = () => {
    const [isLoading, setLoading] = useState(true);
    const activeRegion = useSelector(getSelectedRegion);
    const authorised = auth.isAuthenticated();

    useEffect(() => {
        if (activeRegion && isLoading) {
            initAppInsights(activeRegion.appInsightsConnectionString);
            setLoading(false);
        }
    }, [activeRegion, isLoading]);

    if (!authorised) {
        return <App />;
    } else if (isLoading) {
        return (
            <div className="loader__container loader__container--overlay">
                <Spin />
            </div>
        );
    } else {
        return (
            <AppInsightsContext.Provider value={reactPlugin}>
                <AppInsightsErrorBoundary
                    onError={() => <h1>Oops, something went wrong. Please try again</h1>}
                    appInsights={reactPlugin}
                >
                    <App />
                </AppInsightsErrorBoundary>
            </AppInsightsContext.Provider>
        );
    }
};

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <AppWithAppInsights />
        </ConnectedRouter>
    </Provider>,
    document.getElementById("root") as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
