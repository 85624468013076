import classNames from "classnames";
import { formatOrderDate } from "features/groupTabs/components/OrderItemsSection";
import { getIcon } from "features/location/components/RowOrderId";
import React from "react";
import { KeyValueRow, Text } from "sharedComponents";
import { Order } from "../types";
import { capitalizeFirstLetter } from "../utils";
import "../assets/OrderInfo.scss";

interface Props {
    order: Order;
}

export const OrderInfo = ({ order }: Props) => {
    return (
        <div className="order-Info">
            <KeyValueRow
                key={`order-Info_${order.dateSubmitted}`}
                className="order-Info__date"
                item={{
                    label: <Text mode="bold" value={formatOrderDate(order.dateSubmitted)} />,
                    value: (
                        <>
                            <Text className="order-Info__status">{capitalizeFirstLetter(order.status)}</Text>
                            <span
                                className={classNames("order-Info__icon", {
                                    error: order.status === "orphaned",
                                    warning: order.status === "resolved",
                                })}
                            >
                                {getIcon(order.status)}
                            </span>
                        </>
                    ),
                }}
            />
        </div>
    );
};
