// Declared in public/config.(env).js to avoid having to modify these scripts at
// deploy time, which messes with the source maps
const globalConfig: { [key: string]: string | undefined } = (window as any).meanduConfig;

export const config: AppConfiguration = {
    ...globalConfig,
} as any;

export interface AppConfiguration {
    VITE_CMS: string;
    VITE_OPS_API: string;
    VITE_AUTH0_CLIENT_ID: string;
    VITE_AUTH0_DOMAIN: string;
    VITE_AUTH0_CALLBACK_URL: string;
    VITE_LEGACY_REGION: string;
}
