import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { PartyPage, Props } from "../components/PartyPage";
import { getPartyMember, getPartyTransactions, isSingleUser } from "../selectors";
import { PageLoadingProps, withFailed } from "common/loader/withLoader";
import { loadPartyLocation } from "../components/loadPartyLocation";
import { RouteComponentProps } from "react-router";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<PageLoadingProps<Props>> => {
    const {
        party: { party },
    } = state;

    if (party.status === "loaded") {
        const visiblePartyTransactions = getPartyTransactions(state);
        const partyMember = getPartyMember(state);
        const memberId = match.params["member_id"];

        return {
            loadStatus: party.status,
            noTransactions: !visiblePartyTransactions.length,
            singleUser: isSingleUser(state),
            displayMemberParties: !!memberId,
            notGroupTabAdmin: !!party.data.groupTab && partyMember?.id !== party.data.groupTab.owner,
        };
    }

    return {
        loadStatus: party.status,
    };
};

export const PartyPageContainer = connect(mapStateToProps)(loadPartyLocation(withFailed(PartyPage)));
