import React from "react";
import { Currency, KeyValueRow, Text } from "sharedComponents";
import { Order } from "../types";
import "../assets/OrderTotal.scss";

interface Props {
    order: Order;
    locale: string;
    currency: string;
}

export const OrderTotal = ({ order, locale, currency }: Props) => {
    return (
        <KeyValueRow
            className="order-total"
            key={`order-total_${order.dateSubmitted}`}
            item={{
                label: <Text mode="bold" value="Total order amount" />,
                value: (
                    <Text mode="bold">
                        {<Currency locale={locale!} currency={currency!} value={order.orderTotal} />}
                    </Text>
                ),
            }}
        />
    );
};
