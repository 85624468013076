import { AppDispatch, AppState } from "app/store";
import { actionCreators } from "../../party/reducers";
import { actionCreators as localActionCreators } from "../reducers";
import { emailInvoice } from "../../party/api";
import { message } from "antd";

export function emailPartyMember(partyId: string, memberId: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(localActionCreators.emailInvoiceToMemberExecuting(memberId));

        try {
            const result: boolean = await emailInvoice(partyId, memberId);
            if (result) {
                message.success("Email successfully sent");
            } else {
                message.error("Unable to send email");
            }
            dispatch(localActionCreators.emailInvoiceToMemberExecuted());
        } catch (e) {
            console.log(e);
            message.error("Unable to send email");
            dispatch(actionCreators.emailFailed(e));
        }
    };
}
