import { opsApi } from "common/api";
import { LocationPartyResult } from "../types";
import moment from "moment";

type GraphQLPayload = { locationParties: LocationPartyResult };

const getLocationQuery: string = `query ($locationId: ID!, $skip: Int!, $limit: Int!, $searchOrder: String, $dateFrom: String, $dateTo: String, $activeParties: Boolean!) {
    locationParties(locationId: $locationId, skip: $skip, limit: $limit, searchOrder: $searchOrder, dateFrom: $dateFrom, dateTo: $dateTo, activeParties: $activeParties) {
        totalCount
        data {
            id
            locationId
            partyType
            memberId
            memberName
            tableNumber
            closeReason
            hadProblem
            hadWarning
            amount
            locale
            currency
            date
            paymentMethod
            isFlexTab
        }
    }
  }`;

export async function fetchParties(
    locationId: string,
    skip: number,
    limit: number,
    searchOrder: string | null,
    dateFrom: string | null,
    dateTo: string | null,
    activeParties: boolean
) {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(getLocationQuery, {
            locationId,
            skip,
            limit,
            searchOrder,
            dateFrom: formatDate(dateFrom),
            dateTo: formatDate(dateTo, true),
            activeParties,
        });
        return res.data.locationParties;
    } catch (e) {
        throw e;
    }
}

function formatDate(date: string | null, withEndTime?: boolean) {
    return date && date?.length > 0 ? moment(date, "DD/MM/YY").format("YYYY-MM-DD 00:00:00") : null;
}
