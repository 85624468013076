import React, { useEffect } from "react";
import { Spinner, DateFormatted } from "sharedComponents";
import { MemberPartyData } from "..";
import { Tables } from "sharedComponents/dataDisplay/tables";
import { LayoutType, FourFields } from "sharedComponents/dataDisplay/tables/types";
import { RowOrderId } from "features/location/components/RowOrderId";
import "../assets/MemberParties.scss";
import { useDispatch } from "react-redux";
import { actionCreators as memberActionCreators } from "../../member/reducers";

export interface Props {
    memberId: string | undefined;
    totalCount: number;
    displayPage: number;
    loading: boolean;
    selectedParty?: string;
    parties: MemberPartyData[];
    getMemberParties: (memberId: string, skip: number, limit: number) => void;
    goToPage: (page: number) => void;
    showParty: (partyId: string, memberId: string) => void;
    activeTab: number;
}

const columnData = [
    {
        title: "Location",
    },
    {
        title: "Party ID",
        render: (order: string, item: FourFields) => <RowOrderId order={order} orderData={item.originalData} />,
    },
    {
        title: "Last activity",
        render: (date: string) => <DateFormatted value={date} />,
    },
    {
        title: "Payment",
    },
];

const ROWS_PER_PAGE = 9;

export const MemberParties = ({
    parties,
    loading,
    displayPage,
    memberId,
    totalCount,
    getMemberParties,
    goToPage,
    showParty,
    selectedParty,
    activeTab,
}: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!memberId) {
            dispatch(memberActionCreators.reset());
        } else if (activeTab === 0) {
            getMemberParties(memberId, ROWS_PER_PAGE * (displayPage - 1), ROWS_PER_PAGE);
        }
    }, [getMemberParties, memberId, displayPage, activeTab, dispatch]);

    let partyData = parties;
    if (selectedParty) {
        const selectedPartyIndex = parties.findIndex((party) => party.id === selectedParty);
        if (selectedPartyIndex < 3) {
            partyData = parties.slice(0, 5);
        } else if (selectedPartyIndex > parties.length - 3) {
            partyData = parties.slice(parties.length - 5);
        } else {
            partyData = parties.slice(selectedPartyIndex - 2, Math.min(selectedPartyIndex + 3, parties.length));
        }
    }

    return (
        <>
            {loading && !partyData.length ? (
                <Spinner size="large" />
            ) : (
                <Tables
                    layoutType={selectedParty ? LayoutType.THREE_COLUMNS : LayoutType.FOUR_COLUMNS}
                    dataSource={partyData}
                    selected={selectedParty}
                    tableTitle="Order history"
                    columnData={columnData}
                    startColumn={selectedParty ? 1 : 0}
                    goToPage={goToPage}
                    page={displayPage}
                    selectRow={(row: any) => memberId && showParty(row.id, memberId)}
                    pageSize={ROWS_PER_PAGE}
                    showPagination={!selectedParty}
                    totalCount={totalCount}
                />
            )}
        </>
    );
};
