import { opsApi } from "common/api";
import { auth } from "common/auth";
import { getLegacyRegion } from ".";
import { Region } from "./types/region";

const REGION_KEY = "MEANDU_REGION_ID";

const getRegionFromPath = () => {
    const regionPath = window.location.pathname.split("/")[1];
    return validRegionPath(regionPath) ? regionPath : null;
};

const validRegionPath = (regionId: string) => /^[a-z]{2,3}$/.test(regionId);

const isDefaultPath = (pathname: string) => pathname.length < 2 || pathname === "/auth/callback";

export class RegionHelper {
    private activeRegion: Region;
    private initialFetch?: Promise<Region>;
    private regionPath: string | null;

    constructor() {
        this.regionPath = getRegionFromPath();
        const storageRegion = localStorage.getItem(REGION_KEY);
        var activeRegion = {
            id:
                this.regionPath ||
                (isDefaultPath(window.location.pathname) && storageRegion ? storageRegion : getLegacyRegion()),
        } as Region;
        this.activeRegion = activeRegion;
        localStorage.setItem(REGION_KEY, this.activeRegion.id);
    }

    init = async (availableRegions: string[] | null) => {
        const initialId = this.activeRegion.id;
        if (
            (isDefaultPath(window.location.pathname) || !this.regionPath) &&
            availableRegions &&
            availableRegions.length &&
            availableRegions.every((r) => r !== this.activeRegion.id)
        ) {
            this.activeRegion.id = availableRegions[0];
        }

        this.initialFetch = this.fetchRegion();
        const res = await this.initialFetch;

        this.setActiveRegion(res);
        this.initialFetch = undefined;

        if (res.id !== initialId) {
            window.location.replace(`/${res.id}`);
        }

        return res;
    };

    getActiveRegion = () => this.activeRegion;

    setActiveRegion = (activeRegion: Region) => {
        this.activeRegion = activeRegion;
        localStorage.setItem(REGION_KEY, this.activeRegion.id);
    };

    fetchRegion = async (region?: string) => {
        if (this.initialFetch) {
            await this.initialFetch;
        }

        const init = region
            ? {
                  headers: {
                      "x-meandu-region": region,
                  },
              }
            : undefined;

        const regionRes = await opsApi.fetch(`/region`, init);

        return (await regionRes.json()) as Region;
    };

    addRegion = (init?: RequestInit) => {
        if (init && init.headers && (init.headers as any)["x-meandu-region"]) {
            return init;
        }

        if (!this.activeRegion.id) {
            return undefined;
        }
        return {
            ...init,
            headers: {
                ...(init ? init.headers : undefined),
                "x-meandu-region": this.activeRegion.id,
            },
        };
    };

    isLegacyRegion = () => this.activeRegion.id === getLegacyRegion();

    hasAccess = () => {
        if (this.initialFetch) {
            return null;
        }
        const availableRegions = auth.getAvailableRegions();
        return availableRegions
            ? availableRegions.some((id) => this.activeRegion.id === id)
            : this.activeRegion.id === getLegacyRegion();
    };

    getStoredRegion = () => localStorage.getItem(REGION_KEY);
}
