import React, { useMemo } from "react";
import { Order } from "../types";
import { Text, Section, Spinner, Collapse } from "sharedComponents";
import { NoData } from "./NoData";
import { PartyLink } from "./PartyLink";
import { OrderInfo } from "./OrderInfo";
import { OrderItems } from "./OrderItems";
import { OrderTotal } from "./OrderTotal";

export interface Props {
    orders?: Order[];
    locale?: string;
    currency?: string;
    isPayAndOrder?: boolean;
    loading: boolean;
    locationId?: string;
    partyId?: string;
    viaCustomerPage: boolean;
}

export const OrdersSection = ({
    loading,
    orders,
    locale,
    currency,
    locationId,
    partyId,
    isPayAndOrder,
    viaCustomerPage,
}: Props) => {
    const sortedOrders = useMemo(
        () => orders?.slice().sort((a, b) => b.dateSubmitted.localeCompare(a.dateSubmitted, locale)),
        [orders, locale]
    );

    return (
        <Section>
            <Text preset="header" mode="label" value="Orders" />
            {loading ? (
                <Spinner size="large" />
            ) : viaCustomerPage && !isPayAndOrder && partyId && locationId ? (
                <PartyLink
                    linkText="here"
                    message="Customer orders are not available. View party information "
                    locationId={locationId}
                    partyId={partyId}
                />
            ) : !sortedOrders || !sortedOrders?.length ? (
                <NoData />
            ) : (
                <Collapse>
                    {sortedOrders.map((order, index) => (
                        <Collapse.Panel key={`order_${index}`} header={<OrderInfo order={order} />}>
                            <OrderItems
                                orderItems={order.items}
                                locale={locale!}
                                currency={currency!}
                                isPayAndOrder={isPayAndOrder}
                            />
                            <OrderTotal order={order} locale={locale!} currency={currency!} />
                        </Collapse.Panel>
                    ))}
                </Collapse>
            )}
        </Section>
    );
};
