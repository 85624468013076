import React from "react";
import { Spinner, Currency, Divider } from "sharedComponents";
import { Text } from "sharedComponents";
import { Tables } from "sharedComponents/dataDisplay/tables";
import { LayoutType, FourFields } from "sharedComponents/dataDisplay/tables/types";
import { TickIcon, FailedIcon } from "sharedComponents/assets/Icons";
import { KeyValueRow } from "sharedComponents/dataDisplay/keyValueRow";
import "../assets/BillSplitTab.scss";
import { NoData } from "./NoData";
import { region } from "features/region";

export interface Props {
    memberId?: string;
    loaded: boolean;
    billSplits?: FourFields[];
    paymentsTotal?: number;
    locale?: string;
    currency?: string;
    partyId?: string;
}

export const BillSplitTab = ({ billSplits, partyId, loaded, memberId, locale, currency, paymentsTotal }: Props) => (
    <div className="bill-split">
        {!loaded ? (
            <Spinner size="large" />
        ) : !billSplits || !billSplits.length ? (
            <NoData />
        ) : (
            <>
                <Tables
                    layoutType={LayoutType.FOUR_COLUMNS}
                    dataSource={billSplits}
                    selected={memberId}
                    columnData={columnData(partyId!)}
                    pageSize={billSplits.length}
                />
                <Divider />
                <KeyValueRow
                    mode="emphasis"
                    item={{
                        label: "Total charged",
                        value: <Currency currency={currency!} locale={locale!} value={paymentsTotal!} />,
                    }}
                />
            </>
        )}
    </div>
);

const columnData = (partyId: string) => [
    {
        title: "Customer",
        render: (member: string, item: FourFields) => (
            <Text mode="link" key={"member" + member + item.id}>
                <a href={`/${region.getActiveRegion().id}/member/${item.originalData.memberId}/1/party/${partyId}`}>
                    {member}
                </a>
            </Text>
        ),
    },
    {
        title: "Customer ID",
    },
    {
        title: "Transaction ID",
        render: (transactionId: string, item: FourFields) => (
            <div key={"transaction" + transactionId + item.id} style={{ display: "flex", alignItems: "center" }}>
                {item.success ? <TickIcon /> : <FailedIcon />}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.originalData.transactionLink}
                    style={{ marginLeft: 6 }}
                >
                    {transactionId}
                </a>
            </div>
        ),
    },
    {
        title: "Payment",
        render: (amount: string, item: FourFields) => <Text mode="label">{amount}</Text>,
    },
];
