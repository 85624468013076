import { AppState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";
import { Party, PartyType, PosOrderData, Refund } from "../types";
import { Loaded } from "common/loader";
import { getPartyLocale } from "../utils/partyLocaleCache";

export const getParty = (state: AppState): Party =>
    state.party.party.status === "loaded" ? state.party.party.data : undefined;

export const getMember = (state: AppState) => (state.member.info.status === "loaded" ? state.member.info.data : undefined);

export const isSingleUser = createSelector(getParty, (party) => !party || party.members.length < 2);

export const isOpenTable = createSelector(getParty, (party) => party && party.type === PartyType.MULTIUSER);

export const getIsPayAndOrder = createSelector(getParty, (party) => party && party.type === PartyType.SINGLEUSER);

export const getPartyMember = createSelector(getParty, getMember, getIsPayAndOrder, (party, member, isPayAndOrder) => {
    if (!party?.members) {
        return undefined;
    }
    return party.members.find((m) => m.id === member?.id) || isPayAndOrder ? party.members[0] : undefined;
});

export const getPartyTransactions = createSelector(getParty, (party) => [...party.payments, ...(party.refunds || [])]);

export const getPartyLastRefund = createSelector(getParty, (party): Refund | undefined => {
    if (!party?.refunds?.length) return undefined;
    const refund: Refund = party.refunds[party.refunds.length - 1];
    if (!refund) return undefined;

    let status = refund.status;
    switch (refund.status) {
        case "complete":
            status = "Refunded";
            break;
        case "pending":
            status = "Pending";
            break;
        case "failed":
            status = "Failed";
            break;
    }
    return {
        ...refund,
        status,
    };
});

export const getBillSplit = createSelector(
    getParty,
    (party) =>
        party?.payments
            ?.filter((p) => p.statusSeverity === "SUCCESS")
            ?.sort(({ memberId: a }, { memberId: b }) => a.localeCompare(b))
            ?.map((payment) => ({
                id: payment.memberId,
                success: true,
                originalData: payment,
                field1: payment.memberDisplayName,
                field2: payment.memberId,
                field3: payment.transactionId,
                field4: getPartyLocale(party.locale, party.currency).formatCurrency(payment.transactionTotal || 0),
            })) ?? []
);
export const getPartyPosOrderIds = createSelector(getParty, (party) =>
    party.posOrders.map((p) => p.posOrderId!).filter((id) => id)
);

export const getPosOrderData = (state: AppState): Loaded<PosOrderData> => state.party.posOrderData;
