import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { TransactionTab, Props } from "../components/TransactionTab";
import { RouteComponentProps, withRouter } from "react-router";
import { getIsPayAndOrder, getPartyTransactions } from "../selectors";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        party: { party },
    } = state;

    const viaCustomerPage = !match.params["location_id"];

    if (party.status === "loaded") {
        const partyTransactions = getPartyTransactions(state);

        return {
            payments: partyTransactions,
            locale: party.data.locale,
            currency: party.data.currency,
            loading: false,
            locationId: party.data.location.id,
            partyId: party.data.id,
            isPayAndOrder: getIsPayAndOrder(state),
            viaCustomerPage: viaCustomerPage,
        };
    }
    return {
        loading: true,
        viaCustomerPage,
    };
};
export const TransactionTabContainer = withRouter(connect(mapStateToProps)(TransactionTab));
