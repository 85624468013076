import {
    ApplicationInsights,
    ICustomProperties,
    IEventTelemetry,
    IPageViewTelemetry,
    SeverityLevel,
    Snippet,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin, useTrackEvent as useCustomEvent } from "@microsoft/applicationinsights-react-js";
import { useRef } from "react";
import { Region } from "features/region/types/region";
import { getUserTelemetryInitializer } from "./plugins/UserTelemetryInitializer";
import { history } from "app/store";

const reactPlugin = new ReactPlugin();

const snippet: Snippet = {
    config: {
        // Has to be set to empty string to avoid initialization error:
        // "Uncaught Error: Please provide instrumentation key"
        instrumentationKey: "",
        // Has to be set to true to avoid initalization error: 
        // "Invalid Instrumentation key"
        disableInstrumentationKeyValidation: true,
        connectionString: "",
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        extensionConfig: {
            [reactPlugin.identifier]: { history: history },
        },
    },
};
const appInsights = new ApplicationInsights(snippet);

let inited = false;

function initAppInsights(region?: Region | null) {
    if (region && region.appInsightsConnectionString) {
        snippet.config.connectionString = region.appInsightsConnectionString;
        if (inited) {
            appInsights.flush();
            appInsights.updateSnippetDefinitions(snippet);
        } else {
            inited = true;
            appInsights.loadAppInsights();
            appInsights.addTelemetryInitializer(getUserTelemetryInitializer());
        }
    }
}

function useTrackEvent<T>(eventName: string, eventData: T, skipFirstRun: boolean = true) {
    const trackEvent = useRef(useCustomEvent(reactPlugin, "Supportal/" + eventName, eventData, skipFirstRun));
    return trackEvent.current;
}

function trackPageView(uri: string, additionalPageViewData: IPageViewTelemetry = {}) {
    if (additionalPageViewData.name) {
        additionalPageViewData.name = "Supportal/" + additionalPageViewData.name;
    }

    reactPlugin.trackPageView({
        uri,
        ...additionalPageViewData,
    });
}

function trackException(message?: string, severity: SeverityLevel = SeverityLevel.Error) {
    appInsights.trackException({ error: new Error(message), severityLevel: severity });
}

function trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
    if (event.name) {
        event.name = "Supportal/" + event.name;
    }

    reactPlugin.trackEvent(event, customProperties);
}

export { reactPlugin, appInsights, initAppInsights, useTrackEvent, trackPageView, trackException, trackEvent };
