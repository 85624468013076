import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { select, updateEmail } from "../actions";
import { MemberInfoSection, Props } from "../components/MemberInfoSection";
import { getIsPayAndOrder, getParty } from "features/party/selectors";
import { getGroupTabOwner } from "features/groupTabs/selectors";
import { PartyMember } from "features/party/types";
import { MemberValue } from "../types";
import { Loaded } from "common/loader";
import { emailPartyMember } from "../actions/emailPartyMember";
import { emailGroupTabMember } from "../actions/emailGroupTabMember";

const mapToPartyMember = (memberInfo: Loaded<MemberValue>, memberId: string | undefined) => {
    if (memberId && memberInfo.status === "loaded" && memberInfo.data) {
        const member = memberInfo.data;
        const partyMember: PartyMember = {
            id: member.id,
            displayName: member.firstName + " " + member.lastName,
            email: member.email,
            phoneNumber: member.phoneNumber,
            credits: member.credits,
            currency: member.currency,
            locale: member.locale,
        };
        return partyMember;
    }
    return undefined;
};

const getPartyMembers = (
    memberId: string | undefined,
    partyMember: PartyMember | undefined,
    partyMembers: PartyMember[] | undefined
) => {
    if (memberId) {
        return partyMember ? [partyMember] : partyMembers?.filter((m) => m.id === memberId);
    }
    return partyMembers;
};

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        member: { info, emailInvoice },
        groupTab: {
            email: { status },
        },
    } = state;

    const party = getParty(state);
    const groupTabId = match.params["grouptab_id"];
    const groupTabOwnerId = getGroupTabOwner(state);
    const isPayAndOrder = getIsPayAndOrder(state);
    //User must have arrived here from a member parties page when there is no locationId
    const memberId = match.params["member_id"] ?? (party ? undefined : groupTabOwnerId);
    const partyMember = mapToPartyMember(info, memberId);

    return {
        members: getPartyMembers(memberId, partyMember, party?.members),
        memberId: memberId,
        party: party,
        groupTabId: groupTabId,
        loading: info.status === "loading" || (!memberId && !party),
        groupTabOwnerId: groupTabOwnerId,
        isPayAndOrder,
        groupTabBeingSentInvoice: status === "loading",
        memberIdBeingSentInvoice: emailInvoice.status === "loading" ? emailInvoice.data : undefined,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, { match }: RouteComponentProps<any>): FunctionProps<Props> => {
    return {
        getMember: (memberId: string) => dispatch(select(memberId)),
        updateMemberEmail: (memberId: string, email: string) => {
            dispatch(updateEmail(memberId, email));
        },
        onPartyMemberEmailInvoiceClick: (partyId: string, memberId: string) => {
            dispatch(emailPartyMember(partyId, memberId));
        },
        onGroupTabMemberEmailInvoiceClick: (groupTabId: string, ownerId: string) => {
            dispatch(emailGroupTabMember(groupTabId, ownerId));
        },
    };
};

export const MemberInfoContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberInfoSection));
