import React, { useMemo } from "react";
import { Order } from "..";
import { KeyValueRow } from "sharedComponents/dataDisplay/keyValueRow";
import { Spinner, Divider, PercentageKeyValueRow } from "sharedComponents";
import { Currency, Text } from "sharedComponents";
import "../assets/PaymentTab.scss";
import { NoData } from "./NoData";
import { Party, PartyGroupTab, PartyStatus, Refund, TaxTotal } from "../types";
import { region } from "../../region";
import { getProcessingFeeRowTitle } from "../utils/currencyHelper";
import { PartyLink } from "./PartyLink";

export interface Props {
    loading: boolean;
    party?: Party;
    order?: Order;
    locale?: string;
    currency?: string;
    groupTab?: PartyGroupTab;
    locationId?: string;
    refund?: Refund;
    partyId?: string;
    isPayAndOrder?: boolean;
    viaCustomerPage: boolean;
}

export const PaymentTab = ({
    loading,
    party,
    order,
    locale,
    currency,
    groupTab,
    locationId,
    refund,
    partyId,
    isPayAndOrder,
    viaCustomerPage,
}: Props) => {
    const hasVenueServiceFee = !!party?.venueServiceFeePercentage && !!party.venueServiceFeeAmount;
    const membershipDiscount = useMemo(() => {
        if (!order || !order.membershipDiscount) {
            return 0;
        }
        return order.membershipDiscount > 0 ? -order.membershipDiscount : order.membershipDiscount;
    }, [order]);

    return loading ? (
        <Spinner size="large" />
    ) : viaCustomerPage && !isPayAndOrder && partyId && locationId ? (
        <PartyLink
            linkText="here"
            message="Customer payments are not available. View party information "
            locationId={locationId}
            partyId={partyId}
        />
    ) : (!order && isPayAndOrder) || !party ? (
        <NoData />
    ) : (
        <div className="party-payment">
            {order && (
                <>
                    {!!order.discount && (
                        <KeyValueRow
                            item={{
                                label: <>Venue discount</>,
                                value: <Currency locale={locale!} currency={currency!} value={order.discount || 0} />,
                            }}
                        />
                    )}
                    {!!membershipDiscount && (
                        <KeyValueRow
                            mode="emphasis"
                            item={{
                                label: `Member discount: ${order.membershipLevelName}`,
                                value:
                                    order.orderTotal === order.subtotal ? (
                                        <>
                                            (
                                            <Currency
                                                locale={locale!}
                                                currency={currency!}
                                                value={membershipDiscount}
                                            />
                                            )
                                        </>
                                    ) : (
                                        <Currency locale={locale!} currency={currency!} value={membershipDiscount} />
                                    ),
                            }}
                        />
                    )}
                    <KeyValueRow
                        disabled={!order.orderTotal}
                        item={{
                            label: "Item total",
                            value: <Currency locale={locale!} currency={currency!} value={order.orderTotal} />,
                        }}
                    />
                    <PercentageKeyValueRow
                        label="Venue surcharge"
                        percentage={order.surcharge?.percentage}
                        formatOptions={{ maximumFractionDigits: 2 }}
                        value={order.surcharge?.total || 0}
                        locale={locale!}
                        currency={currency!}
                    />
                    {!!order.promotions?.length &&
                        order.promotions.map((orderPromotion, index) => (
                            <KeyValueRow
                                key={"promotion_" + index}
                                item={{
                                    label: `Promotions: ${orderPromotion.name}`,
                                    value: (
                                        <Currency locale={locale!} currency={currency!} value={orderPromotion.amount} />
                                    ),
                                }}
                            />
                        ))}
                    {!!order.cepOffers?.length &&
                        order.cepOffers.map((orderPromotion, index) => (
                            <KeyValueRow
                                key={"offer_" + index}
                                item={{
                                    label: `Offers: ${orderPromotion.name}`,
                                    value: (
                                        <Currency locale={locale!} currency={currency!} value={orderPromotion.amount} />
                                    ),
                                }}
                            />
                        ))}
                    {!!order.trayCharges?.length &&
                        order.trayCharges.map((orderTrayCharge, index) => (
                            <KeyValueRow
                                key={"trayCharge_" + index}
                                item={{
                                    label: orderTrayCharge.name || "Venue tray charge",
                                    value: (
                                        <Currency
                                            locale={locale!}
                                            currency={currency!}
                                            value={orderTrayCharge.amount}
                                        />
                                    ),
                                }}
                            />
                        ))}

                    {!!order.venueCauses?.length &&
                        order.venueCauses.map((orderVenueCause, index) => (
                            <KeyValueRow
                                key={"venueCause_" + index}
                                item={{
                                    label: "Venue cause",
                                    value: (
                                        <Currency
                                            locale={locale!}
                                            currency={currency!}
                                            value={orderVenueCause.amount}
                                        />
                                    ),
                                }}
                            />
                        ))}

                    {!!order.split && (
                        <KeyValueRow
                            mode="emphasis"
                            item={{
                                label: (
                                    <>
                                        <Text>Split Total </Text>
                                        {!!order.split.memberCount && (
                                            <Text mode="label">&nbsp;(By {order.split.memberCount} users)</Text>
                                        )}
                                    </>
                                ),
                                value: (
                                    <Currency
                                        locale={locale!}
                                        currency={currency!}
                                        value={order.split.individualTotal}
                                    />
                                ),
                            }}
                        />
                    )}
                    <TaxItems taxTotals={party.taxTotals} locale={locale!} currency={currency!} />
                    <Divider />
                </>
            )}
            {!order && (
                <>
                    <KeyValueRow
                        disabled={!party.itemsTotal}
                        item={{
                            label: "Item total",
                            value: <Currency locale={locale!} currency={currency!} value={party.itemsTotal} />,
                        }}
                    />
                    {party.status === PartyStatus.CLOSED && (
                        <TaxItems taxTotals={party.taxTotals} locale={locale!} currency={currency!} />
                    )}
                    <Divider />
                </>
            )}

            {!!groupTab?.gratuityFactor && (
                <PercentageKeyValueRow
                    value={party.tipsTotal || 0}
                    percentage={groupTab.gratuityFactor}
                    locale={locale!}
                    currency={currency!}
                    label="Venue gratuity"
                />
            )}

            {!!party.totalCharged && (
                <>
                    {!!groupTab && !!locationId ? (
                        <KeyValueRow
                            mode="emphasis"
                            item={{
                                label: (
                                    <Text mode={["link", "bold"]}>
                                        <a
                                            href={`/${region.getActiveRegion().id}/location/${locationId}/1/grouptab/${
                                                groupTab.id
                                            }`}
                                        >
                                            Paid from Group Tab
                                        </a>
                                    </Text>
                                ),
                                value: (
                                    <Currency
                                        locale={locale!}
                                        currency={currency!}
                                        value={(party.totalCharged || 0) - (party.refundedTotal || 0)}
                                    />
                                ),
                            }}
                        />
                    ) : (
                        <>
                            {hasVenueServiceFee && (
                                <PercentageKeyValueRow
                                    label="Venue service fee"
                                    percentage={party.venueServiceFeePercentage / 100}
                                    formatOptions={{ maximumFractionDigits: 2 }}
                                    value={party.venueServiceFeeAmount}
                                    locale={locale!}
                                    currency={currency!}
                                />
                            )}
                            <KeyValueRow
                                disabled={!party.tipsTotal}
                                item={{
                                    label: "Tips",
                                    value: (
                                        <Currency locale={locale!} currency={currency!} value={party.tipsTotal || 0} />
                                    ),
                                }}
                            />
                            <KeyValueRow
                                disabled={!party.gratuityTotal}
                                item={{
                                    label: "Gratuity",
                                    value: (
                                        <Currency
                                            locale={locale!}
                                            currency={currency!}
                                            value={party.gratuityTotal || 0}
                                        />
                                    ),
                                }}
                            />
                            <KeyValueRow
                                disabled={!party.pointsPaymentsTotal}
                                item={{
                                    label: "Points",
                                    value: (
                                        <Currency
                                            locale={locale!}
                                            currency={currency!}
                                            value={0 - (party.pointsPaymentsTotal || 0)}
                                        />
                                    ),
                                }}
                            />
                            {!!party.credits && (
                                <KeyValueRow
                                    item={{
                                        label: "Credits",
                                        value: (
                                            <Currency
                                                locale={locale!}
                                                currency={currency!}
                                                value={party.credits || 0}
                                            />
                                        ),
                                    }}
                                />
                            )}
                            <KeyValueRow
                                disabled={!party.processingFeeTotal}
                                item={{
                                    label: getProcessingFeeRowTitle(currency),
                                    value: (
                                        <Currency
                                            locale={locale!}
                                            currency={currency!}
                                            value={party.processingFeeTotal || 0}
                                        />
                                    ),
                                }}
                            />
                            {!!party.refundedTotal && (
                                <KeyValueRow
                                    item={{
                                        label: "Total paid",
                                        value: (
                                            <Currency
                                                locale={locale!}
                                                currency={currency!}
                                                value={party.totalCharged || 0}
                                            />
                                        ),
                                    }}
                                />
                            )}
                            {!!party.refundedTotal && (
                                <KeyValueRow
                                    mode="green"
                                    item={{
                                        label: "Total refunded",
                                        value: (
                                            <Text>
                                                {
                                                    <Currency
                                                        locale={locale!}
                                                        currency={currency!}
                                                        value={0 - (party.refundedTotal || 0)}
                                                    />
                                                }
                                            </Text>
                                        ),
                                    }}
                                />
                            )}
                            <KeyValueRow
                                mode="emphasis"
                                item={{
                                    label: "Total charged",
                                    value: (
                                        <Currency
                                            locale={locale!}
                                            currency={currency!}
                                            value={
                                                (party.totalCharged || 0) -
                                                (party.refundedTotal || 0) -
                                                (party.pointsPaymentsTotal || 0)
                                            }
                                        />
                                    ),
                                }}
                            />
                            {!!order?.stampsEarned && (
                                <>
                                    <Divider />
                                    <KeyValueRow
                                        item={{
                                            label: "Stamps earned",
                                            value: <Text>{order.stampsEarned}</Text>,
                                        }}
                                    />
                                </>
                            )}
                        </>
                    )}
                </>
            )}
            {!!party.totalOutstanding && (
                <KeyValueRow
                    mode="emphasis"
                    item={{
                        label: "Total outstanding",
                        value: <Currency locale={locale!} currency={currency!} value={party.totalOutstanding} />,
                    }}
                />
            )}
            {!!refund && !party.refundedTotal && refund.status !== "Refunded" && (
                <>
                    <Divider />
                    <KeyValueRow
                        mode={refund.status !== "Failed" ? "mild" : "red"}
                        item={{
                            label: `${refund.status} refund`,
                            value: (
                                <Text>
                                    {
                                        <Currency
                                            locale={locale!}
                                            currency={currency!}
                                            value={0 - (refund.transactionTotal || 0)}
                                        />
                                    }
                                </Text>
                            ),
                        }}
                    />
                </>
            )}
        </div>
    );
};

const TaxItems = ({ taxTotals, locale, currency }: { taxTotals?: TaxTotal[]; locale: string; currency: string }) => (
    <>
        {taxTotals?.map((t) =>
            t.exclusive ? (
                <KeyValueRow
                    key={`${t.taxClass}_${t.taxRate}`}
                    item={{
                        label: (
                            <>
                                {"Tax"} {t.taxClass}
                            </>
                        ),
                        value: <Text>{<Currency locale={locale!} currency={currency!} value={t.total} />}</Text>,
                    }}
                />
            ) : (
                <KeyValueRow
                    key={`${t.taxClass}_${t.taxRate}`}
                    mode="mild"
                    item={{
                        label: (
                            <>
                                {"Included"} {t.taxClass}{" "}
                                {
                                    <Text mode="label">
                                        &nbsp;(
                                        {t.taxRate
                                            .toLocaleString("default", {
                                                style: "percent",
                                                minimumFractionDigits: 1,
                                            })
                                            .replace(".0", "")}
                                        )
                                    </Text>
                                }
                            </>
                        ),
                        value: <Text>({<Currency locale={locale!} currency={currency!} value={t.total} />})</Text>,
                    }}
                />
            )
        )}
    </>
);
