import React from "react";
import classNames from "classnames";
import { Collapse as AntdCollapse } from "antd";
import { CollapseProps } from "antd/lib/collapse";
import "./Collapse.scss";

interface Props extends CollapseProps {
    children: React.ReactNode;
}

const Collapse = ({ className, children, ...rest }: Props) => {
    return (
        <AntdCollapse ghost className={classNames("mnu-collapse", className)} {...rest}>
            {children}
        </AntdCollapse>
    );
};

Collapse.Panel = AntdCollapse.Panel;

export { Collapse };
