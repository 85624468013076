import { Loaded } from "common/loader";
import { PartyType } from "features/party";

export type State = {
    info: Loaded<MemberValue>;
    parties: Loaded<MemberPartyResult>;
    groupTabs: Loaded<MemberGroupTabResult>;
    emailInvoice: Loaded<string | undefined>;
    memberTab: number;
};

export type MemberValue = {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    email?: string;
    credits?: number;
    currency?: string;
    locale?: string;
    memberType: MemberType;
};

export enum MemberType {
    guest = 0,
    authenticated,
}

export type MemberPartyResult = {
    data: MemberPartyData[];
    totalCount: number;
};
export type MemberPartyData = {
    id: string;
    locationName: string;
    locationId: string;
    partyType: PartyType;
    tableNumber: string;
    closeReason: string;
    hadProblem?: string;
    hadWarning?: string;
    amount?: number;
    locale: string;
    currency: string;
    date: string;
    paymentMethod?: string;
    isFlexTab: boolean;
};

export type MemberGroupTabResult = {
    data: MemberGroupTabData[];
    totalCount: number;
};

export type MemberGroupTabData = {
    id: string;
    locationName: string;
    tabName: string;
    status: string;
    locationId: string;
    creditsTotal?: number;
    locale: string;
    currency: string;
    dateOpened: string;
    dateClosed: string;
    closeReason: string;
};
