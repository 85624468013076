import React from "react";
import { KeyValueRow, Text } from "sharedComponents";
import { formatOrderDate } from "features/groupTabs/components/OrderItemsSection";
import { IPayment } from "..";
import "../assets/TransactionInfo.scss";

interface Props {
    payment: IPayment;
}

export const TransactionInfo = ({ payment }: Props) => {
    return (
        <div className="transation-info">
            <KeyValueRow
                className="transation-info__date"
                item={{
                    label: <Text mode="bold" value={formatOrderDate(payment.date)} />,
                    value: <Text>{payment.memberDisplayName}</Text>,
                }}
            />
        </div>
    );
};
