import React from "react";
import { Route } from "react-router";
import { FeatureConfiguration } from "app/featureConfig";
import { GraphiqlPage } from "./components/GraphiqlPage";

export default function (config: FeatureConfiguration) {
    config.routes.locationRoutes.push(
        <Route path="/:region/graphiql" exact key="graphiql" component={GraphiqlPage} />
    );
}
