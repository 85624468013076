import React from "react";
import { MemberValue } from "../types";
import { Content, Section, Tabs } from "sharedComponents";
import { MemberInfoContainer } from "../containers/MemberInfoContainer";
import { BreadcrumbContainer } from "features/breadcrumb/containers/BreadcrumbContainer";
import { MemberPartiesContainer } from "../containers/MemberPartiesContainers";
import { MemberGroupTabsContainer } from "../containers/MemberGroupTabsContainer";

export interface Props {
    member?: MemberValue;
    activeTab: number;
    setActiveTab: (activeTab: number) => void;
}

const tabs = [
    {
        content: <MemberPartiesContainer />,
        label: "Parties",
    },
    {
        content: <MemberGroupTabsContainer />,
        label: "GROUP TABS",
    },
];

export const MemberPage = ({ member, activeTab, setActiveTab }: Props) => (
    <>
        <Content>
            <>
                <MemberInfoContainer />
            </>
            <Section>
                <Tabs activeIndex={activeTab} tabPanes={tabs} onActiveTab={(index) => setActiveTab(index)} />
            </Section>
        </Content>
        <BreadcrumbContainer />
    </>
);
