import { message } from "antd";
import { AppDispatch, AppState } from "app/store";
import { MemberValue } from "..";
import { updateMemberEmail, UpdateMemberEmailModel } from "../api/updateMemberEmailApi";
import { actionCreators } from "../reducers";
import { select as partySelect } from "../../party/actions";
import { actionCreators as partyActionCreators } from "../../party/reducers";
import { getParty } from "features/party/selectors";

export function updateEmail(memberId: string, email: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(actionCreators.infoLoading());

        try {
            const result: MemberValue = await updateMemberEmail(mapUpdateModel(memberId, email));
            dispatch(actionCreators.infoLoaded(result));
            message.success("Member updated");
            const party = getParty(getState());
            if(party)
            {
                dispatch(partyActionCreators.reset());
                dispatch(partySelect(party.id, party.location.id));
            }
        } catch (e) {
            console.log(e);
            const memberInfo = getState().member.info.data;
            message.error("Error updating member");
            dispatch(actionCreators.infoLoaded(memberInfo));
        }
    };
}

const mapUpdateModel = (memberId: string, email: string): UpdateMemberEmailModel => ({
    memberId,
    email,
});
