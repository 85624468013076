import React from "react";
import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { Breadcrumb, Props } from "../components/Breadcrumb";
import { region } from "features/region";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        member: { info },
        location: { info: locationInfo },
    } = state;
    const crumbs: (string | React.ReactNode)[] = [];

    if (match.params["member_id"] && info.status === "loaded" && !match.params["location_id"]) {
        const memberName = info.data ? `${info.data?.firstName} ${info.data?.lastName}` : match.params["member_id"];
        if (match.params["party_id"] || match.params["grouptab_id"]) {
            crumbs.push(
                <span>
                    <a
                        href={`/${region.getActiveRegion().id}/member/${match.params["member_id"]}/${
                            match.params["page"] || 1
                        }`}
                    >
                        {memberName}
                    </a>
                    &nbsp;{'>'}&nbsp;
                </span>
            );
        } else {
            crumbs.push(memberName);
        }
    } else if (match.params["location_id"] && locationInfo.status === "loaded") {
        const locationName = locationInfo.data?.displayName;
        if (match.params["party_id"] || match.params["grouptab_id"]) {
            crumbs.push(
                <span>
                    <a
                        href={`/${region.getActiveRegion().id}/location/${match.params["location_id"]}/${
                            match.params["page"] || 1
                        }`}
                    >
                        {locationName}
                    </a>
                    &nbsp;{'>'}&nbsp;
                </span>
            );
        } else {
            crumbs.push(locationName);
        }
    }
    if (match.params["party_id"]) {
        crumbs.push(`${match.params["party_id"]}`);
    }

    if (match.params["grouptab_id"]) {
        crumbs.push(`${match.params["grouptab_id"]}`);
    }

    return {
        crumbs,
    };
};

export const BreadcrumbContainer = withRouter(connect(mapStateToProps)(Breadcrumb));
