import { opsApi } from "common/api";
import { Party } from "../types";

type GraphQLPayload = { party: Party };

const itemsQuery = `
items {
    displayName
    variantName
    member {
        id
        displayName
    }
    quantity
    unitPrice
    lineTotal
    sku
    modifiers {
        displayName
        sku
        unitPrice
        nestedModifiers {
            displayName
            unitPrice
            nestingLevel
        }
    }
    failedToSubmit
    memberNotes
}`;

const getPartyQuery: string = `query ($partyId: ID!, $locationId: ID!) {
    party(partyId: $partyId, locationId: $locationId) {
        id
        location {
            id
            displayName
        }
        type
        section {
            id
            displayName
        }
        service {
            id
            displayName
        }
        tableNumber
        closeReason
        closeReasonSeverity
        locale
        currency
        dateOpened
        dateSubmitted
        dateScheduled
        isTakeaway,
        orderWithPayment {
            ${itemsQuery}
            subtotal
            surcharge {
                percentage
                total
            }
            discount
            split {
                individualTotal
                memberCount
            }
            membershipDiscount
            membershipLevelName
            membershipLevelId
            promotions {
                name
                amount
            }
            cepOffers {
                name
                amount
            }
            trayCharges {
                name
                amount
            }
            dateSubmitted
            status
            orderTotal
            venueCauses {
                name
                amount
            }
            stampsEarned
        }
        ordersWithoutPayment {
            ${itemsQuery}
            dateSubmitted
            status
            orderTotal
        }
        posOrders {
			posOrderId
		}
        paymentsTotal
        pointsPaymentsTotal
        tipsTotal
        gratuityTotal
        credits
        processingFeeTotal
        venueServiceFeePercentage
        venueServiceFeeAmount
        totalCharged
        refundedTotal
        totalOutstanding
        itemsTotal
        taxTotals {
            taxClass
            taxRate
            total
            exclusive
        }
        payments {
            id
            memberId
            transactionTotal
            transactionId
            transactionLink
            error {
                code
                message
            }
            paymentMethod
            date
            status
            statusSeverity
            posStatus
        }
        refunds {
            refundId
            paymentId
            transactionTotal
            transactionId
            transactionLink
            error {
                code
                message
            }
            date
            status
            statusSeverity
        }
        members {
            id
            displayName
            email
            phoneNumber
        }
        groupTab {
            id
            tabName
            packageName
            owner
            gratuityFactor
        }
        status
        isFlexTab
    }
  }`;

export async function getParty(partyId: string, locationId: string) {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(getPartyQuery, { partyId, locationId });
    return res.data.party || {};
}
