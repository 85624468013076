import { regionMiddleware } from "./regionMiddleware";
import { reducer } from "./reducers";
import { RegionHelper } from "./regionHelper";
import { FeatureConfiguration } from "app/featureConfig";
import { config } from "common/config";

export const region = new RegionHelper();

export function getLegacyRegion() {
    return config.VITE_LEGACY_REGION!;
}

export default function (config: FeatureConfiguration) {
    config.middleware.push(regionMiddleware());
    config.reducers.region = reducer;
}
