import React from "react";
import { ListItemProps } from "antd/lib/list/Item";
import classNames from "classnames";
import "./Item.scss";
import { Tooltip } from "antd";
import { Text } from "sharedComponents";

export interface Props extends ListItemProps {
    normal?: boolean;
    label?: string | JSX.Element;
    value?: string | JSX.Element;
    disabled?: boolean;
    children?: string | JSX.Element;
}

export const Item = ({ label, value, className, children, normal, disabled }: Props) => {
    const [copied, setCopied] = React.useState(false);
    const copyValue = async () => {
        if (typeof value === "string") {
            try {
                await navigator.clipboard.writeText(value);
                setCopied(true);
            } catch (err) {
                console.error("Failed to copy: ", err);
            }
        }
    };
    const resetCopied = () => setCopied(false);
    return (
        <div className={classNames("item", className)} onMouseLeave={resetCopied}>
            {label && (
                <div className="item-label">
                    <Text mode={normal ? "" : "label"} disabled={disabled}>
                        {label}
                    </Text>
                </div>
            )}
            <Tooltip
                title={normal ? undefined : copied ? "Copied" : "Copy"}
                mouseEnterDelay={0.5}
                trigger="hover"
                placement="bottom"
            >
                <span className="item-value" onClick={normal ? undefined : copyValue}>
                    {value ? (
                        <Text mode={normal ? "" : "bold"} disabled={disabled}>
                            {value}
                        </Text>
                    ) : (
                        children
                    )}
                </span>
            </Tooltip>
        </div>
    );
};
