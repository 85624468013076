import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { RouteComponentProps, withRouter } from "react-router";
import { OrdersSection, Props } from "../components/OrdersSection";
import { getIsPayAndOrder } from "../selectors";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        party: { party },
    } = state;

    const viaCustomerPage = !match.params["location_id"];

    if (party.status === "loaded") {
        const isPayAndOrder = getIsPayAndOrder(state);
        return {
            orders: isPayAndOrder
                ? party.data.orderWithPayment
                    ? [party.data.orderWithPayment]
                    : undefined
                : party.data.ordersWithoutPayment,
            locale: party.data.locale,
            currency: party.data.currency,
            isPayAndOrder: isPayAndOrder,
            partyId: party.data.id,
            locationId: party.data.location.id,
            viaCustomerPage,
            loading: false,
        };
    } else {
        return {
            loading: party.status === "loading",
            viaCustomerPage,
        };
    }
};

export const OrdersContainer = withRouter(connect(mapStateToProps)(OrdersSection));
