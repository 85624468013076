import { AppState } from "app/store";
import { getPaymentColumnValue } from "features/location/selectors";
import { MemberGroupTabData, MemberPartyData } from "features/member/types";
import { getPartyLocale } from "features/party/utils/partyLocaleCache";

export const getMembersParties = (state: AppState) => {
    return state.member.parties.status === "loaded"
        ? state.member.parties.data.data.map((memberPartyData: MemberPartyData) => ({
              id: memberPartyData.id,
              originalData: memberPartyData,
              success: memberPartyData.closeReason === "completed",
              field1: memberPartyData.locationName,
              field2: memberPartyData.tableNumber,
              field3: memberPartyData.date,
              field4: getPaymentColumnValue(
                  memberPartyData.partyType,
                  memberPartyData.locale,
                  memberPartyData.currency,
                  memberPartyData.isFlexTab,
                  memberPartyData.amount,
                  memberPartyData.paymentMethod
              ),
          }))
        : [];
};

export const getMembersGroupTabs = (state: AppState) => {
    return state.member.groupTabs.status === "loaded"
        ? state.member.groupTabs.data.data.map((value: MemberGroupTabData) => ({
              id: value.id,
              originalData: value,
              success: value.status.toLocaleLowerCase() === "closed",
              field1: value.locationName,
              field2: value.tabName,
              field3:
                  value.status.toLocaleLowerCase() === "closed"
                      ? value.dateOpened + "," + value.dateClosed
                      : value.dateOpened,
              field4: getPartyLocale(value.locale, value.currency).formatCurrency(value?.creditsTotal || 0),
          }))
        : [];
};
