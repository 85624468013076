import React from "react";
import { PaymentTotal, TabType } from "..";
import { KeyValueRow } from "sharedComponents/dataDisplay/keyValueRow";
import { Spinner, Divider, PercentageKeyValueRow } from "sharedComponents";
import { Currency, Text } from "sharedComponents";
import "../assets/PaymentTab.scss";
import { NoData } from "../../party/components/NoData";
import { getProcessingFeeRowTitle } from "features/party/utils/currencyHelper";

export interface Props {
    loading: boolean;
    locale?: string;
    currency?: string;
    paymentTotals?: PaymentTotal;
    limit?: number;
    isTabClosed?: boolean;
    gratuityFactor?: number;
    tabTipAmount?: number;
    tabType?: TabType;
}

export const PaymentTab = ({
    loading,
    paymentTotals,
    locale,
    currency,
    limit,
    isTabClosed,
    gratuityFactor,
    tabTipAmount,
    tabType,
}: Props) => {
    return loading ? (
        <Spinner size="large" />
    ) : !paymentTotals || !paymentTotals.totalCharged ? (
        <NoData />
    ) : (
        <div className="groupTab-payment">
            <KeyValueRow
                item={{
                    label: "Limit",
                    value: limit ? <Currency locale={locale!} currency={currency!} value={limit} /> : "N/A",
                }}
            />

            <KeyValueRow
                item={{
                    label: "Group Tab spend",
                    value: <Currency locale={locale!} currency={currency!} value={paymentTotals?.total || 0} />,
                }}
            />
            <Divider />
            {isTabClosed && (
                <>
                    <KeyValueRow
                        disabled={true}
                        item={{
                            label: "Venue surcharge",
                            value: <Currency locale={locale!} currency={currency!} value={paymentTotals.surcharges} />,
                        }}
                    />
                    <TaxItems
                        paymentTotals={paymentTotals}
                        locale={locale!}
                        currency={currency!}
                        disabled={tabType === TabType.PayOnOrder}
                    />
                    {!!gratuityFactor && (
                        <PercentageKeyValueRow
                            locale={locale!}
                            currency={currency!}
                            value={paymentTotals.totalGratuity || 0}
                            percentage={gratuityFactor}
                            disabled={true}
                            label="Venue gratuity"
                        />
                    )}

                    {!!tabTipAmount && (
                        <KeyValueRow
                            disabled={true}
                            item={{
                                label: "Tip",
                                value: <Currency locale={locale!} currency={currency!} value={tabTipAmount} />,
                            }}
                        />
                    )}
                </>
            )}

            {!!paymentTotals && !!paymentTotals?.totalCharged && (
                <>
                    {!!paymentTotals.totalRefunded && (
                        <KeyValueRow
                            item={{
                                label: "Refunded",
                                value: (
                                    <Text>
                                        {
                                            <Currency
                                                locale={locale!}
                                                currency={currency!}
                                                value={paymentTotals.totalRefunded || 0}
                                            />
                                        }
                                    </Text>
                                ),
                            }}
                        />
                    )}
                    <KeyValueRow
                        disabled={tabType === TabType.PayOnOrder || !paymentTotals.processingFee}
                        item={{
                            label: getProcessingFeeRowTitle(currency),
                            value: (
                                <Currency
                                    locale={locale!}
                                    currency={currency!}
                                    value={paymentTotals.processingFee || 0}
                                />
                            ),
                        }}
                    />
                    <KeyValueRow
                        mode="emphasis"
                        item={{
                            label: "Total charged",
                            value: (
                                <Currency
                                    locale={locale!}
                                    currency={currency!}
                                    value={paymentTotals.totalCharged || 0}
                                />
                            ),
                        }}
                    />
                </>
            )}
        </div>
    );
};

const TaxItems = ({
    paymentTotals,
    locale,
    currency,
    disabled,
}: {
    paymentTotals: PaymentTotal;
    locale: string;
    currency: string;
    disabled: boolean;
}) => (
    <>
        {paymentTotals?.taxSummary &&
            paymentTotals?.taxSummary.length > 0 &&
            paymentTotals?.taxSummary.map((item, index) => {
                return (
                    <KeyValueRow
                        disabled={disabled || !item.exclusive}
                        key={`tax_item_${item.taxClass}_${item.taxRate}_${index}`}
                        item={{
                            label: (
                                <>
                                    {item.exclusive ? "Tax" : "Included"} {item.taxClass}{" "}
                                    {!item.exclusive ? (
                                        <Text mode="label">
                                            &nbsp;(
                                            {item.taxRate
                                                .toLocaleString("default", {
                                                    style: "percent",
                                                    minimumFractionDigits: 1,
                                                })
                                                .replace(".0", "")}
                                            )
                                        </Text>
                                    ) : (
                                        ""
                                    )}
                                </>
                            ),
                            value: item.exclusive ? (
                                <Text>
                                    {" "}
                                    <Currency locale={locale!} currency={currency!} value={item.total || 0} />
                                </Text>
                            ) : (
                                <Text>
                                    (<Currency locale={locale!} currency={currency!} value={item.total || 0} />)
                                </Text>
                            ),
                        }}
                    />
                );
            })}
    </>
);
