import React from "react";
import { Currency, Divider, KeyValueRow, Text } from "sharedComponents";
import { OrderItem } from "../types";
import "../assets/OrderItems.scss";
import { region } from "features/region";

interface Props {
    orderItems: OrderItem[];
    locale: string;
    currency: string;
    isPayAndOrder?: boolean;
}

interface ItemDetailsProps {
    item: OrderItem;
    locale: string;
    currency: string;
    isPayAndOrder?: boolean;
}

interface ItemModifierProps {
    item: OrderItem;
    locale: string;
    currency: string;
    index: number;
}

const ItemDetails = ({ item, locale, currency, isPayAndOrder }: ItemDetailsProps) => (
    <>
        {!isPayAndOrder && item.member?.displayName && (
            <Text className="order-items--row__member-name" mode={["link", "bold"]}>
                <a href={`/${region.getActiveRegion().id}/member/${item.member.id}/1`}>{item.member.displayName}</a>
            </Text>
        )}
        <Text mode="bold" className="order-items--row__price">
            <Currency locale={locale} currency={currency} value={item.lineTotal || item.unitPrice || 0} />
        </Text>
    </>
);

const ItemModifiers = ({ item, locale, currency, index }: ItemModifierProps) => (
    <>
        {!!item.modifiers?.length &&
            item.modifiers.map((m, mIndex) => (
                <div key={`item_modifier_${item.sku}_${m.sku}_${mIndex}_${index}`} className="order-items--row__modifiers">
                    <KeyValueRow
                        className={item.failedToSubmit ? "failed" : ""}
                        key={`order_modifier_${m.sku}_${mIndex}_${index}`}
                        item={{
                            label: (
                                <>
                                    <Text className="order-items--row__quantity" value="" />
                                    {m.displayName}
                                </>
                            ),
                            value: (
                                <>
                                    (
                                    <Currency locale={locale} currency={currency} value={m.unitPrice} />)
                                </>
                            ),
                        }}
                    />
                    {m.nestedModifiers &&
                        m.nestedModifiers.map((nestedModifier, nIndex) => (
                            <KeyValueRow
                                className={item.failedToSubmit ? "failed" : ""}
                                key={`order_nested_modifier_${m.sku}_${mIndex}_${index}_${nIndex}`}
                                item={{
                                    label: (
                                        <>
                                            <Text className="order-items--row__quantity" value="" />{"- ".repeat(nestedModifier.nestingLevel)}
                                            {nestedModifier.displayName}
                                        </>
                                    ),
                                    value: (
                                        <>
                                            (
                                            <Currency
                                                locale={locale!}
                                                currency={currency!}
                                                value={nestedModifier.unitPrice}
                                            />
                                            )
                                        </>
                                    ),
                                }}
                            />
                        ))}
                </div>
            ))}
    </>
);

export const OrderItems = ({ orderItems, locale, currency, isPayAndOrder }: Props) => {
    return (
        <div className="order-items">
            {orderItems.map((item, index) => (
                <div className="order-items--row" key={`order_row_item_${item.sku}_${index}`}>
                    <KeyValueRow
                        className={item.failedToSubmit ? "failed" : ""}
                        key={`order_item_${item.sku}_${index}`}
                        item={{
                            label: (
                                <>
                                    <Text className="order-items--row__quantity">{item.quantity}x</Text>
                                    <Text mode="bold" value={item.displayName} />
                                    &nbsp;
                                    {item.variantName && <Text mode="bold" value={`/ ${item.variantName}`} />}
                                </>
                            ),
                            value: (
                                <ItemDetails
                                    item={item}
                                    locale={locale!}
                                    currency={currency!}
                                    isPayAndOrder={isPayAndOrder}
                                />
                            ),
                        }}
                    />
                    <>
                        <ItemModifiers item={item} index={index} locale={locale} currency={currency} />
                        {item.memberNotes && (
                            <KeyValueRow
                                className={item.failedToSubmit ? "failed" : ""}
                                mode="mild"
                                key={`order_item_note_${item.sku}_${index}`}
                                item={{ label: `Note: ${item.memberNotes}` }}
                            />
                        )}
                    </>
                    <Divider key={`order_item_divider_${item.sku}_${index}`} />
                </div>
            ))}
        </div>
    );
};
