import React from "react";

export const NavIcon = () => (
    <svg width="100" height="32" viewBox="0 0 100 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.5204 0.557822C0.785739 2.10138 -0.988727 6.37315 0.559852 10.1003C2.10592 13.8274 6.38773 15.5944 10.1224 14.0533C13.8571 12.5097 15.629 8.23797 14.0829 4.51335C12.5369 0.783708 8.25506 -0.985738 4.5204 0.557822Z"
            fill="black"/>
        <path
            d="M4.52032 17.0572C0.785663 18.6008 -0.986293 22.8726 0.559776 26.5972C2.10585 30.3243 6.38766 32.0913 10.1223 30.5502C13.857 29.0067 15.6289 24.7349 14.0829 21.0103C12.5368 17.2831 8.25499 15.5162 4.52032 17.0572Z"
            fill="black"/>
        <path
            d="M37.9739 6.14966C35.2256 6.14966 33.5289 7.4849 32.4447 8.65198C31.4608 7.11093 29.8671 6.14966 27.7287 6.14966C25.3543 6.14966 23.8258 7.21133 22.842 8.41104V6.491H18.771V24.4816H22.842V13.9277C22.842 11.3576 24.639 9.98722 26.3708 9.98722C28.5419 9.98722 29.3576 11.5985 29.3576 13.8248V24.4816H33.4285V13.9277C33.4285 11.3576 35.2256 9.98722 36.9574 9.98722C39.1284 9.98722 39.9441 11.5985 39.9441 13.8248V24.4816H44.0151V12.6251C44.0151 8.75238 41.6408 6.14966 37.9764 6.14966H37.9739Z"
            fill="black"/>
        <path
            d="M95.9291 6.49416V17.1861C95.9291 19.7562 94.0969 21.1266 92.2647 21.1266C90.0585 21.1266 89.1098 19.7211 89.1098 17.5275V6.49416H85.0388V18.4185C85.0388 22.2912 87.3454 24.8613 91.0775 24.8613C93.3841 24.8613 94.9126 23.7645 95.9291 22.5647V24.4848H100V6.49416H95.9291Z"
            fill="black"/>
        <path
            d="M83.7413 13.3055H79.3566C79.2462 14.5403 78.8446 15.6773 78.2071 16.6185L73.9228 12.3518C77.0726 10.8007 78.9299 9.29475 79.0454 6.49125H74.8865C74.8238 8.04736 73.9253 8.72502 71.4229 9.82182C70.3613 8.68235 69.6184 7.75872 69.6184 6.50129V6.48874H65.206C65.2035 6.56905 65.201 6.64686 65.201 6.72717C65.201 8.83043 66.2476 10.3715 67.4248 11.7067C64.3301 13.4335 62.9472 15.4364 62.9472 18.1696C62.9472 20.1649 63.7679 21.8616 65.324 23.0738C66.7973 24.2234 68.8729 24.8533 71.177 24.8533C73.8023 24.8533 76.1892 24.0778 78.2723 22.5468L80.2325 24.4944H86.1156L81.1762 19.5726C82.4512 17.7404 83.523 15.5669 83.7388 13.2955V13.3055H83.7413ZM71.5309 21.0183C68.7876 21.0183 67.5578 19.3643 67.5578 17.7203C67.5578 16.2496 68.243 15.2431 69.9195 14.2793L75.514 19.806C74.3419 20.6092 73.0016 21.0183 71.5309 21.0183Z"
            fill="black"/>
        <path
            d="M57.6162 19.1106C57.0665 20.2853 55.4653 21.2666 54.0221 21.2666C51.4344 21.2666 49.6223 19.4018 49.3186 16.3473H61.6797V14.9895C61.6797 10.1028 58.959 6.20247 53.8514 6.20247C48.7439 6.20247 45.4535 10.2408 45.4535 15.4638C45.4535 20.9604 48.9798 24.7252 54.0196 24.7252C57.5459 24.7252 60.49 22.2454 61.7324 19.1257L61.6897 19.1081H57.6137H57.6162V19.1106ZM53.8213 9.62842C56.3738 9.62842 57.5836 11.3251 57.6162 13.2928H49.5194C50.1243 10.8833 51.7356 9.62842 53.8188 9.62842H53.8213Z"
            fill="black"/>
    </svg>
);
