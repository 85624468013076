import { Loaded } from "common/loader";

export type State = {
    party: Loaded<Party>;
    rawParty: Loaded<string>;
    email: Loaded<boolean>;
    posOrderData: Loaded<PosOrderData>;
};

export type Party = {
    id: string;
    location: DataReference;
    type: PartyType;
    section?: DataReference;
    service?: DataReference;
    tableNumber: string;
    closeReason: string;
    closeReasonSeverity: DataSeverity;
    currency: string;
    locale: string;
    dateOpened?: string;
    dateSubmitted?: string;
    dateScheduled?: string;
    isTakeaway?: boolean;
    orderWithPayment: Order;
    ordersWithoutPayment?: Order[] | null;
    posOrders: PosOrderReference[];
    paymentsTotal: number;
    pointsPaymentsTotal?: number;
    members: PartyMember[];
    payments: Payment[];
    refunds?: Refund[];
    groupTab?: PartyGroupTab;
    memberId?: string;
    taxTotals?: TaxTotal[];
    tipsTotal: number;
    gratuityTotal: number;
    credits: number;
    processingFeeTotal: number;
    venueServiceFeePercentage: number;
    venueServiceFeeAmount: number;
    totalCharged: number;
    refundedTotal: number;
    totalOutstanding: number;
    itemsTotal: number;
    status?: PartyStatus;
    isFlexTab: boolean;
};

export type Order = {
    items: OrderItem[];
    orderTotal: number;
    subtotal: number;
    surcharge?: Surcharge;
    discount: number;
    split?: Split;
    membershipDiscount: number;
    membershipLevelName: string;
    membershipLevelId: string;
    promotions?: OrderPromotion[] | null;
    cepOffers?: OrderPromotion[] | null;
    trayCharges?: OrderTrayCharge[] | null;
    dateSubmitted: string;
    status: string;
    venueCauses?: OrderVenueCause[] | null;
    stampsEarned?: number | null;
};

export type OrderItem = {
    displayName: string;
    variantName?: string;
    member?: DataReference;
    quantity: number;
    unitPrice?: number;
    lineTotal?: number;
    sku?: string;
    modifiers?: Modifier[];
    failedToSubmit: boolean;
    memberNotes?: string;
};

export interface OrderPromotion {
    name: string;
    amount: number;
}

export interface OrderTrayCharge {
    name: string;
    amount: number;
}

export type Modifier = {
    sku?: string;
    displayName: string;
    unitPrice: number;
    nestedModifiers?: NestedModifier[];
};

export type NestedModifier = {
    displayName: string;
    unitPrice: number;
    nestingLevel: number;
}

export type PosOrderReference = {
    posOrderId?: string;
};

export type PosOrderData = {
    contentType: string;
    data: string;
};

export type Surcharge = {
    percentage?: number;
    total: number;
};

export type Split = {
    individualTotal: number;
    memberCount: number;
};

export type PartyMember = {
    id: string;
    displayName: string;
    email?: string;
    phoneNumber?: string;
    credits?: number;
    currency?: string;
    locale?: string;
};

export interface Payment extends IPayment {
    id: string;
    memberId: string;
}

export interface Refund extends IPayment {
    paymentId: string;
    refundId: string;
}

export interface IPayment {
    transactionTotal: number;
    transactionId?: string;
    transactionLink: string;
    error?: Error;
    paymentMethod: string;
    date: string;
    status: string;
    posStatus: string;
    statusSeverity: DataSeverity;
    memberDisplayName?: string;
}

export interface OrderVenueCause {
    name: string;
    amount: number;
}

export type Error = {
    code: string;
    message: string;
};

export type DataReference = {
    id: string;
    displayName: string;
};

export type TaxTotal = {
    taxClass: string;
    taxRate: number;
    total: number;
    exclusive: boolean;
};

export type PartyGroupTab = {
    id: string;
    tabName: string;
    packageName?: string;
    owner: string;
    gratuityFactor?: number;
};

export enum PartyType {
    MULTIUSER = "MultiUser",
    SINGLEUSER = "SingleUser",
    PAYONLY = "PayOnly",
}

export enum PartyStatus {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
    PENDINGCLOSE = "PENDINGCLOSE",
}

export type DataSeverity = "SUCCESS" | "FAILURE" | "WARNING" | "INFORMATION" | "LOW";
