import { isValidNumber, ParsedNumber, parseNumber } from "libphonenumber-js";
import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, AutoCompleteOption, Text } from "sharedComponents";
import { DropDown } from "sharedComponents/controls/dropDown";
import { EMAIL_REGEX, MEANDU_ID } from "..";

export interface Props {
    searchKey: string;
    setSearchKey: (key: string) => void;
    options: AutoCompleteOption[];
    loading?: boolean;
    search: (value: string, force?: boolean) => void;
    select: (value: string) => void;
}

const UsersSearchBy: {
    [key: string]: {
        key: string;
        display: string;
        placeholder: string;
    };
} = {
    memberId: {
        key: "memberId",
        display: "Customer ID",
        placeholder: "Enter customer ID",
    },
    name: {
        key: "name",
        display: "Name",
        placeholder: "Lastname, Firstname",
    },
    phone: {
        key: "phone",
        display: "Phone number",
        placeholder: "Eg: +61412345678",
    },
    email: {
        key: "email",
        display: "Email address",
        placeholder: "Enter email address",
    },
};

export const SearchUsers = ({ searchKey, setSearchKey, search, select, options, loading }: Props) => {
    const [error, setError] = useState<string | undefined>();
    const onChangeFormatter = useCallback(
        (value: string) => {
            if (!value) setError(undefined);
            if (searchKey !== UsersSearchBy.phone.key) return value;
            return `+${value.replace(/[^0-9]+/g, "").replace(/^0/, "61")}`;
        },
        [searchKey, setError]
    );

    const onSearchInternal = useCallback(
        (value: string, force?: boolean) => {
            switch (searchKey) {
                case UsersSearchBy.phone.key:
                    if (value && !isValidNumber(parseNumber(value) as ParsedNumber)) {
                        setError("Invalid phone number");
                        return;
                    }
                    break;
                case UsersSearchBy.email.key:
                    if (value && !EMAIL_REGEX.test(value)) {
                        setError("Invalid email");
                        return;
                    }
                    break;
                case UsersSearchBy.memberId.key:
                    if (value && !MEANDU_ID.test(value)) {
                        setError("Invalid memberId, expected 24 alphanumeric characters");
                        return;
                    }
                    break;
            }
            setError(undefined);
            search(value, force);
        },
        [searchKey, search]
    );

    useEffect(() => {
        return () => setError(undefined);
    }, [searchKey]);

    return (
        <div className="search__body">
            <Text className="search__title" mode="bold" preset="subtitle" value="Search for users" />
            <Text
                className="search__description"
                preset="body"
                mode="block"
                value="Find specific users by searching by customer ID, name, phone number or email address."
            />
            <Text className="search__by" preset="body" mode="block" value="Search by" />
            <div className="search__inputs__two">
                <DropDown
                    className="search__inputs__left"
                    options={[UsersSearchBy.memberId, UsersSearchBy.name, UsersSearchBy.phone, UsersSearchBy.email]}
                    onSelect={(key: string, option: string) => {
                        setSearchKey(key);
                    }}
                />
                <Autocomplete
                    loading={loading}
                    options={options}
                    key={"users-orders-" + searchKey}
                    onSearch={onSearchInternal}
                    error={error}
                    onSelect={(value) => select(value)}
                    placeholder={UsersSearchBy[searchKey]?.placeholder || "Search"}
                    onChangeFormatter={onChangeFormatter}
                />
            </div>
        </div>
    );
};
