import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { PaymentTab, Props } from "../components/PaymentTab";
import { getIsPayAndOrder, getPartyLastRefund } from "../selectors";
import { RouteComponentProps, withRouter } from "react-router";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        party: { party },
    } = state;
    const viaCustomerPage = !match.params["location_id"];
    if (party.status === "loaded") {
        return {
            order: getIsPayAndOrder(state) ? party.data.orderWithPayment : null,
            currency: party.data.currency,
            locale: party.data.locale,
            party: party.data,
            loading: party.status === "loading",
            groupTab: party.data.groupTab,
            locationId: party.data.location.id,
            refund: getPartyLastRefund(state),
            partyId: party.data.id,
            isPayAndOrder: getIsPayAndOrder(state),
            viaCustomerPage: viaCustomerPage,
        };
    } else {
        return {
            loading: party.status === "loading",
            viaCustomerPage: viaCustomerPage,
        };
    }
};

export const PaymentTabContainer = withRouter(connect(mapStateToProps)(PaymentTab));
