import React from "react";
import "./App.scss";
import { Switch, Route, Redirect, RouteComponentProps } from "react-router";
import { auth } from "./common/auth";
import { withLogin, CallbackPageContainer } from "features/login";
import { withRegion } from "features/region/components/withRegion";
import { getLegacyRegion, region } from "features/region";
import { HomePageContainer } from "features/login/containers/HomePageContainer";

const ProtectRoute = withLogin(withRegion(HomePageContainer), () => auth);

const RedirectToActiveRegion = withLogin(
    () => <Redirect to={`/${region.getActiveRegion().id}`} />,
    () => auth
);

const RedirectToDefaultRegion = withRegion((props: RouteComponentProps) =>
    region.isLegacyRegion() ? (
        <Redirect to={`/${getLegacyRegion()}${props.location.pathname}`} />
    ) : (
        <Redirect to={`/${getLegacyRegion()}`} />
    )
);

const App = () => (
    <Switch>
        <Route path="/auth/callback" render={(props) => <CallbackPageContainer auth={auth} {...props} />} />
        <Route path="/" exact render={RedirectToActiveRegion} />
        <Route path="/:region([a-z]{2,3})" component={ProtectRoute} />

        <Route render={RedirectToDefaultRegion} />
    </Switch>
);

export default App;
