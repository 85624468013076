import React, { useEffect, useState } from "react";
import { Button, Tabs } from "sharedComponents";
import { Modal } from "sharedComponents/layouts/modal";
import { SearchGroupTabsContainer } from "../containers/SearchGroupTabsContainer";
import { SearchLocationsContainer } from "../containers/SearchLocationsContainer";
import { SearchOrdersContainer } from "../containers/SearchOrdersContainer";
import { SearchUsersContainer } from "../containers/SearchUsersContainer";
import "./Search.scss";

export interface Props {
    activeTab: number;
    setActiveTab: (activeTab: number) => void;
    disabled?: boolean;
}

export const Search = ({ activeTab, setActiveTab, disabled }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const closeModal = () => setIsOpen(false);
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("search-open");
        } else {
            document.body.classList.remove("search-open");
        }
    }, [isOpen]);
    return (
        <>
            <Button search onClick={() => setIsOpen(true)} value="Search" disabled={disabled} />
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <Tabs
                    activeIndex={activeTab}
                    tabPanes={[
                        {
                            label: "Users",
                            content: <SearchUsersContainer onSelectComplete={closeModal} />,
                        },
                        {
                            label: "Parties",
                            content: <SearchOrdersContainer onSelectComplete={closeModal} />,
                        },
                        {
                            label: "Group Tabs",
                            content: <SearchGroupTabsContainer onSelectComplete={closeModal} />,
                        },
                        {
                            label: "Locations",
                            content: <SearchLocationsContainer onSelectComplete={closeModal} />,
                        },
                    ]}
                    onActiveTab={(index) => setActiveTab(index)}
                />
            </Modal>
        </>
    );
};
