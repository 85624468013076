import { AppDispatch, AppState } from "app/store";
import { actionCreators } from "../reducers";
import { getParty } from "../api";
import { Party } from "../types";

export function select(partyId: string, locationId: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const partyInfo = getState().party.party;
        if (partyInfo.status === "loaded" && partyInfo.data.id === partyId) return;

        dispatch(actionCreators.loading());

        try {
            const result: Party = mapParty(await getParty(partyId, locationId));

            dispatch(actionCreators.loaded(result));
        } catch (e) {
            dispatch(actionCreators.failed(e.message));
        }
    };
}

export const sortItemWithDate = (a: Date, b: Date) => {
    return Number(a) - Number(b);
};

const mapParty = (party: Party): Party => {
    const mappedParty: Party = {
        ...party,
        payments: party.payments.map((payment) => ({
            ...payment,
            memberDisplayName: party.members.find((member) => member.id === payment.memberId)?.displayName,
        })),
    };

    //Refund transactions do not have a memberId, however, they are linked to the actual payment and payment is linked with the member, so we use paymentId to get the member displayName.
    return {
        ...mappedParty,
        refunds: mappedParty.refunds?.map((refund) => ({
            ...refund,
            memberDisplayName: mappedParty.payments.find((payment) => payment.id === refund.paymentId)
                ?.memberDisplayName,
        })),
    };
};
