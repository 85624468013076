import React from "react";
import { GraphiQL } from "graphiql";
import { executeQuery } from "../api/executeQuery";

import "graphiql/graphiql.css";


export const GraphiqlPage = () => {

    return (
        <GraphiQL fetcher={executeQuery} />
    );

};