import { AppDispatch } from "app/store";
import { actionCreators } from "../../groupTabs/reducers";
import { emailInvoice } from "../../groupTabs/api";
import { message } from "antd";

export const emailGroupTabMember = (groupTabId: string, memberId: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(actionCreators.loadingEmail());

        try {
            const result: boolean = await emailInvoice(groupTabId, memberId);

            if (result) {
                message.success("Email successfully sent");
            } else {
                message.error("Unable to send email");
            }
            dispatch(actionCreators.emailLoaded(result));
        } catch (e) {
            message.error("Unable to send email");
            dispatch(actionCreators.emailFailed(e));
        }
    };
};
