import React, { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete, AutoCompleteOption, Text } from "sharedComponents";
import { MEANDU_ID } from "..";

export interface Props {
    locationOptions: AutoCompleteOption[];
    groupTabOptions: AutoCompleteOption[];
    loading?: boolean;
    searchLocation: (value: string, force?: boolean) => void;
    searchGroupTabs: (location: string, value: string, force?: boolean) => void;
    select: (location: string, party: string) => void;
    resetOrders: () => void;
}

export const SearchGroupTabs = ({
    searchLocation,
    searchGroupTabs,
    select,
    locationOptions,
    groupTabOptions,
    loading,
}: Props) => {
    const [error, setError] = useState<string | undefined>();
    const [location, setLocation] = useState<string>();
    const searchGroupTabRef = useRef<any>();

    useEffect(() => {
        if (!!location && !!searchGroupTabRef?.current) {
            (searchGroupTabRef.current! as HTMLElement)?.focus();
        }
    }, [location]);

    const onGroupTabsSearchInternal = useCallback(
        (value: string, force?: boolean) => {
            if (value && !MEANDU_ID.test(value)) {
                setError("Invalid groupTabId, expected 24 alphanumeric character");
                return;
            }
            searchGroupTabs(location!, value, force);
        },
        [location, searchGroupTabs]
    );

    return (
        <div className="search__body">
            <Text className="search__title" mode={["block", "bold"]} preset="subtitle" value="Search for Group Tabs" />
            <Text
                className="search__description"
                preset="body"
                mode="block"
                value="Enter the location and the ID of the Group Tab you are searching for."
            />
            <div className="search__inputs__two">
                <Autocomplete
                    className="search__inputs__left"
                    loading={loading}
                    options={locationOptions}
                    onSearch={(value, force) => searchLocation(value, force)}
                    onSelect={(value) => setLocation(value)}
                    placeholder={"Location name or slug"}
                />
                <Autocomplete
                    className="search__inputs__right"
                    loading={loading}
                    options={groupTabOptions}
                    disabled={!location}
                    key={"group-tabs"}
                    onSearch={onGroupTabsSearchInternal}
                    onSelect={(value) => select(location!, value)}
                    placeholder={!location ? "Select location first" : "Enter group tab ID"}
                    ref={searchGroupTabRef}
                    error={error}
                    onChangeFormatter={(value: string) => {
                        !value && setError(undefined);
                        return value;
                    }}
                />
            </div>
        </div>
    );
};
