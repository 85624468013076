import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State, MemberValue, MemberPartyResult, MemberGroupTabResult } from "../types";

const MEMBER_TAB_KEY = "MEMBER_TAB_KEY";

const initialState: State = {
    info: { status: "unloaded" },
    parties: { status: "unloaded" },
    groupTabs: { status: "unloaded" },
    memberTab: JSON.parse(window.localStorage.getItem(MEMBER_TAB_KEY) || "0"),
    emailInvoice: { status: "unloaded" },
};

export const memberSlice = createSlice({
    name: "member",
    initialState,
    reducers: {
        reset: (state: State) => {
            state.info = { status: "unloaded" };
            state.parties = { status: "unloaded" };
            state.groupTabs = { status: "unloaded" };
            state.emailInvoice = { status: "unloaded" };
        },
        emailInvoiceToMemberExecuting: (state: State, memberId: PayloadAction<string>) => {
            state.emailInvoice.status = "loading";
            if (state.emailInvoice.status === "loading") {
                state.emailInvoice.data = memberId.payload;
            }
        },
        emailInvoiceToMemberExecuted: (state: State) => {
            state.emailInvoice.status = "loaded";
            if (state.emailInvoice.status === "loaded") {
                state.emailInvoice.data = undefined;
            }
        },
        infoLoading: (state: State) => {
            state.info.status = "loading";
        },
        infoLoaded: (state: State, action: PayloadAction<MemberValue>) => {
            state.info.status = "loaded";
            if (state.info.status === "loaded") {
                state.info.data = action.payload;
            }
        },
        infoFailed: (state: State, action: PayloadAction<string>) => {
            state.info.status = "failed";
            if (state.info.status === "failed") {
                state.info.error = action.payload;
            }
        },
        partiesLoading: (state: State) => {
            state.parties.status = "loading";
        },
        partiesLoaded: (state: State, action: PayloadAction<MemberPartyResult>) => {
            state.parties.status = "loaded";
            if (state.parties.status === "loaded") {
                state.parties.data = action.payload;
            }
        },
        partiesFailed: (state: State, action: PayloadAction<string>) => {
            state.parties.status = "failed";
            if (state.parties.status === "failed") {
                state.parties.error = action.payload;
            }
        },
        groupTabsLoading: (state: State) => {
            state.groupTabs.status = "loading";
        },
        groupTabsLoaded: (state: State, action: PayloadAction<MemberGroupTabResult>) => {
            state.groupTabs.status = "loaded";
            if (state.groupTabs.status === "loaded") {
                state.groupTabs.data = action.payload;
            }
        },
        groupTabsFailed: (state: State, action: PayloadAction<string>) => {
            state.groupTabs.status = "failed";
            if (state.groupTabs.status === "failed") {
                state.groupTabs.error = action.payload;
            }
        },
        setMemberTab: (state: State, action: PayloadAction<number>) => {
            window.localStorage.setItem(MEMBER_TAB_KEY, JSON.stringify(action.payload));
            state.memberTab = action.payload;
        },
    },
});

export const actionCreators = memberSlice.actions;
export const reducer = memberSlice.reducer;
