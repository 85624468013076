import { AppState } from "app/store";
import { LocationGroupTabData, LocationPartyData, LocationValue } from "../types";
import { createSelector } from "@reduxjs/toolkit";
import { getPartyLocale } from "features/party/utils/partyLocaleCache";
import { PaymentMethod } from "common/types";
import { PartyType } from "features/party";

const getParties = (state: AppState) =>
    state.location.parties.status === "loaded" ? state.location.parties.data.data : null;

export const getLocationInfo = (state: AppState) => state.location.info.data as LocationValue;

export const getSupportedPosOperationMap = createSelector(getLocationInfo, (location) =>
    !location
        ? {}
        : location.posMetadata.operations.reduce((map, op) => {
              map[op.name] = op.supported;
              return map;
          }, {} as PosOperationSupportMap)
);

export type PosOperationSupportMap = { [operationName: string]: boolean | undefined };

export const getLocationParties = createSelector(getParties, (parties) => {
    return parties
        ? parties.map((partyData: LocationPartyData) => ({
              id: partyData.id,
              originalData: partyData,
              success: partyData.closeReason && partyData.closeReason === "completed",
              field1: getCustomerName(partyData.partyType, partyData.isFlexTab, partyData.memberName),
              field2: partyData.tableNumber,
              field3: partyData.date,
              field4: getPaymentColumnValue(
                  partyData.partyType,
                  partyData.locale,
                  partyData.currency,
                  partyData.isFlexTab,
                  partyData.amount,
                  partyData.paymentMethod
              ),
          }))
        : [];
});

const getGroupTabs = (state: AppState) =>
    state.location.groupTabs.status === "loaded" ? state.location.groupTabs.data.data : null;

export const getLocationGroupTabs = createSelector(getGroupTabs, (groupTabs) => {
    return groupTabs
        ? groupTabs.map((value: LocationGroupTabData) => ({
              id: value.id,
              originalData: value,
              success: value.status.toLocaleLowerCase() === "closed" && !!value?.dateClosed,
              field1: value.tabName,
              field2:
                  value.members.length > 0
                      ? value.members[0].displayName || value.ownerName || ""
                      : value.ownerName || "",
              field3:
                  value.status.toLocaleLowerCase() === "closed" && !!value?.dateClosed
                      ? value.dateOpened + "," + value.dateClosed
                      : value.dateOpened,
              field4:
                  value?.creditsTotal === null
                      ? "N/A"
                      : getPartyLocale(value.locale, value.currency).formatCurrency(value.creditsTotal || 0),
          }))
        : [];
});

export const getIsMultiUser = (partyType: PartyType) => partyType !== PartyType.SINGLEUSER;

export const getPaymentColumnValue = (
    partyType: PartyType,
    locale: string,
    currency: string,
    isFlexTab: boolean,
    amount?: number,
    paymentMethod?: string
) => {
    switch (partyType) {
        case PartyType.MULTIUSER:
        case PartyType.PAYONLY:
            return getPartyOrderingFlow(partyType, isFlexTab);
        default:
            if (paymentMethod?.toUpperCase() === PaymentMethod.GROUP_TAB) {
                return "Group Tab";
            } else if (amount === null) {
                return "N/A";
            } else {
                return getPartyLocale(locale, currency).formatCurrency(amount || 0);
            }
    }
};

export const getPartyOrderingFlow = (partyType: PartyType, isFlexTab: boolean) => {
    switch (partyType) {
        case PartyType.MULTIUSER:
            return isFlexTab ? "Flex Tabs" : "Flex Tables";
        case PartyType.PAYONLY:
            return "Pay Only";
        default:
            return "Order & Pay";
    }
};

const getCustomerName = (partyType: PartyType, isFlexTab: boolean, memberName: string | null) =>
    getIsMultiUser(partyType) && !isFlexTab ? "–" : memberName ?? "Guest";
