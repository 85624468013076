import React from "react";
import { RouteComponentProps } from "react-router";
import { region } from "..";
import { Spin } from "antd";
import { HomePageContainer } from "features/login/containers/HomePageContainer";
import { isLoaded } from "common/loader/isLoaded";
import { useSelector } from "react-redux";
import { AppState } from "app/store";

export const withRegion =
    <P extends Object>(Component: React.ComponentType<P>): React.FC<P & RouteComponentProps<any>> =>
    (props) => {
        const selectedRegion = useSelector((state: AppState) => state.region.selectedRegion);

        if (isLoaded(selectedRegion)) {
            const hasAccess = region.hasAccess();
            if (hasAccess === true) {
                return <Component {...props} />;
            } else if (hasAccess === false) {
                return (
                    <HomePageContainer />
                );
            }
        }

        return (
            <div className="loader__container loader__container--overlay">
                <Spin />
            </div>
        );
    };
