import { region } from "features/region";
import React from "react";
import { Text } from "sharedComponents";

interface Props {
    linkText: string;
    message: string;
    locationId: string;
    partyId: string;
}

export const PartyLink = ({ linkText, message, locationId, partyId }: Props) => {
    return (
        <div>
            {message}
            <Text mode={["link", "bold"]}>
                <a href={`/${region.getActiveRegion().id}/location/${locationId}/party/${partyId}`}>{linkText}</a>
            </Text>
        </div>
    );
};
