import React, { useEffect } from "react";
import { AppState } from "app/store";
import { Loaded } from "common/loader";
import { PageLoadingProps } from "common/loader/withLoader";
import { LocationValue } from "features/location";
import { useDispatch, useSelector } from "react-redux";
import { Party } from "..";
import { select } from "features/location/actions";

export const loadPartyLocation = <P extends PageLoadingProps<{}>>(Component: React.ComponentType<P>): React.FC<P> => (
    props: any
) => {
    const locationState = useSelector((state: AppState) => state.location.info as Loaded<LocationValue>);
    const partyState = useSelector((state: AppState) => state.party.party as Loaded<Party>);

    const dispatch = useDispatch();

    useEffect(() => {
        if (partyState.status === "loaded") {
            dispatch(select(partyState.data.location.id));
        }
    }, [partyState, dispatch]);

    if (partyState.status !== "loaded") {
        const newProps: any = { ...props, loadStatus: partyState.status };
        return <Component {...newProps} />;
    }

    if (locationState.status !== "loaded") {
        const newProps: any = { ...props, loadStatus: locationState.status };
        return <Component {...newProps} />;
    }

    return <Component {...props} />;
};
