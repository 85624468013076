import React from "react";
import { OrderItem } from "../types";
import { Text, Section, Spinner, Currency, Divider } from "sharedComponents";
import { KeyValueRow } from "sharedComponents/dataDisplay/keyValueRow";
import { NoData } from "../../party/components/NoData";
import "../assets/OrderItemsSection.scss";
import { region } from "../../region";

export interface Props {
    items?: OrderItem[];
    locale?: string;
    currency?: string;
    total?: number;
    loading?: boolean;
    locationId?: string;
    IsTabClosed?: boolean;
}

export const formatOrderDate = (orderDate: string) => {
    const orderSubmittedDate = new Date(orderDate);
    const orderDay = new Intl.DateTimeFormat("en-US", { weekday: "short", month: "short", day: "numeric" }).format(
        orderSubmittedDate
    );
    const orderTime = new Intl.DateTimeFormat("en-US", { hour: "numeric", minute: "numeric", hour12: true }).format(
        orderSubmittedDate
    );
    return orderDay + " • " + orderTime;
};

const ItemDetails = ({
    item,
    locale,
    currency,
    isTabClosed,
}: {
    item: OrderItem;
    locale: string;
    currency: string;
    isTabClosed?: boolean;
}) => (
    <>
        {item.member && (
            <>
                <div className="groupTab-order-items__column">
                    <div className="groupTab-order-items__column--center">
                        <div className="info">
                            <Text
                                className="groupTab-order-items__column__member"
                                mode="bold"
                                value={item?.member?.displayName}
                            />
                            <Text
                                className="groupTab-order-items__column__menuType"
                                mode="block"
                                value={!item?.packageName ? "Full menu" : "Package: " + item.packageName}
                            />
                        </div>
                    </div>
                </div>
            </>
        )}
        <div className="groupTab-order-items__column">
            <div className="groupTab-order-items__column--right">
                <Text className="groupTab-order-items__column__price" mode="bold">
                    <Currency locale={locale} currency={currency} value={item?.amount || 0} />
                </Text>
            </div>
        </div>
    </>
);

export const OrderItemsSection = ({ loading, items, locale, currency, total, locationId, IsTabClosed }: Props) => {
    return (
        <Section className="groupTab-order-items">
            {!items || !items.length ? (
                <NoData title="Orders" />
            ) : (
                <>
                    <Text className="groupTab-order-items__header" preset="header" mode="label" value="Orders" />
                    {!!loading ? (
                        <Spinner size="large" />
                    ) : (
                        <>
                            {items.map((item, index) => {
                                return (
                                    <div
                                        key={`order_row_item_${item.partyId}_${index}`}
                                        className="groupTab-order-items__row"
                                    >
                                        <div className="groupTab-order-items__column">
                                            <Text mode={["link", "bold"]}>
                                                <a
                                                    href={`/${
                                                        region.getActiveRegion().id
                                                    }/location/${locationId}/party/${item.partyId}`}
                                                >
                                                    {formatOrderDate(item.dateCreated)}
                                                </a>
                                            </Text>
                                        </div>
                                        <ItemDetails
                                            item={item}
                                            locale={locale!}
                                            currency={currency!}
                                            isTabClosed={IsTabClosed}
                                        />

                                        <Divider key={`order_item_divider_${item.partyId}_${index}`} />
                                    </div>
                                );
                            })}
                            <KeyValueRow
                                mode="emphasis"
                                item={{
                                    label: "Total order amount",
                                    value: <Currency locale={locale!} currency={currency!} value={total!} />,
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </Section>
    );
};
