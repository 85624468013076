import React, { useMemo } from "react";
import { IPayment, Refund } from "..";
import { KeyValueRow } from "sharedComponents/dataDisplay/keyValueRow";
import { Spinner, DateFormatted, Collapse } from "sharedComponents";
import { Currency, Text } from "sharedComponents";
import classNames from "classnames";
import "../assets/TransactionTab.scss";
import { NoData } from "./NoData";
import { capitalizeFirstLetter } from "../utils";
import { PartyLink } from "./PartyLink";
import { TransactionInfo } from "./TransactionInfo";
import { Props as ItemProps } from "../../../sharedComponents/dataDisplay/item";

export interface Props {
    loading: boolean;
    payments?: IPayment[];
    currency?: string;
    locale?: string;
    locationId?: string;
    partyId?: string;
    isPayAndOrder?: boolean;
    viaCustomerPage: boolean;
}

function isRefund(payment: IPayment) {
    return (payment as Refund).refundId !== undefined;
}

function getTransactionReference(payment: IPayment): ItemProps {
    return !payment.transactionLink
        ? {
              label: "Transaction ID",
              value: <Text mode={["bold"]}>{payment.transactionId || ""}</Text>,
          }
        : {
              label: "Transaction ID",
              value: (
                  <Text mode={["link", "bold"]}>
                      <a target="_blank" rel="noopener noreferrer" href={payment.transactionLink}>
                          {payment.transactionId || ""}
                      </a>
                  </Text>
              ),
          };
}

export const TransactionTab = ({
    loading,
    payments,
    locale,
    currency,
    locationId,
    partyId,
    isPayAndOrder,
    viaCustomerPage,
}: Props) => {
    const sortedPayments = useMemo(
        () => payments?.slice().sort((a, b) => b.date.localeCompare(a.date, locale)),
        [payments, locale]
    );

    return loading ? (
        <Spinner size="large" />
    ) : viaCustomerPage && !isPayAndOrder && partyId && locationId ? (
        <PartyLink
            linkText="here"
            message="Customer transactions are not available. View party information "
            locationId={locationId}
            partyId={partyId}
        />
    ) : (
        <>
            {!sortedPayments ? (
                <NoData />
            ) : (
                <Collapse>
                    {sortedPayments.map((payment, index) => (
                        <Collapse.Panel
                            key={`transaction_row_${payment.transactionId}_${index}`}
                            header={<TransactionInfo payment={payment} />}
                        >
                            <div className={classNames("party-transaction", payment.statusSeverity.toLowerCase())}>
                                <KeyValueRow
                                    item={{
                                        label: isRefund(payment) ? "Amount refunded" : "Amount charged",
                                        value: (
                                            <Currency
                                                locale={locale!}
                                                currency={currency!}
                                                value={payment.transactionTotal}
                                            />
                                        ),
                                    }}
                                />
                                <KeyValueRow item={getTransactionReference(payment)} />
                                {!isRefund(payment) && (
                                    <KeyValueRow item={{ label: "Payment method", value: payment.paymentMethod }} />
                                )}
                                <KeyValueRow item={{ label: "Date", value: <DateFormatted value={payment.date} /> }} />
                                <KeyValueRow
                                    item={{ label: "Gateway status", value: capitalizeFirstLetter(payment.status) }}
                                />
                                <KeyValueRow
                                    item={{
                                        label: "POS response status",
                                        value: capitalizeFirstLetter(payment.posStatus),
                                    }}
                                />
                                <KeyValueRow
                                    item={{
                                        label: "Customer name",
                                        value: payment.memberDisplayName,
                                    }}
                                />
                                {!!payment.error && (
                                    <KeyValueRow item={{ label: "Failure reason", value: payment.error?.message }} />
                                )}
                            </div>
                        </Collapse.Panel>
                    ))}
                </Collapse>
            )}
        </>
    );
};
