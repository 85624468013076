import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { Button } from "antd";
import { Text, Section, Spinner, Collapse, KeyValueRow } from "sharedComponents";
import "../assets/MemberInfoSection.scss";
import { NoData } from "features/party/components/NoData";
import { EMAIL_REGEX } from "features/search";
import { EditableItem } from "sharedComponents/dataDisplay/editableItem";
import { Party, PartyMember } from "features/party";
import { region } from "../../region";

export interface Props {
    members: PartyMember[] | undefined;
    memberId: string | undefined;
    party: Party | undefined;
    loading: boolean;
    groupTabBeingSentInvoice: boolean;
    memberIdBeingSentInvoice: string | undefined;
    groupTabOwnerId: string | undefined;
    groupTabId: string | undefined;
    isPayAndOrder: boolean;
    getMember: (memberId: string) => void;
    updateMemberEmail: (memberId: string, email: string) => void;
    onPartyMemberEmailInvoiceClick: (partyId: string, memberId: string) => void;
    onGroupTabMemberEmailInvoiceClick: (groupTabId: string, memberId: string) => void;
}

export const MemberInfoSection = ({
    members,
    memberId,
    party,
    loading,
    groupTabBeingSentInvoice,
    memberIdBeingSentInvoice,
    groupTabOwnerId,
    groupTabId,
    getMember,
    updateMemberEmail,
    onPartyMemberEmailInvoiceClick,
    onGroupTabMemberEmailInvoiceClick,
}: Props) => {
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        !!memberId && getMember(memberId);
    }, [getMember, memberId]);

    const onUpdateEmail = useCallback(
        (memberId: string, value: string) => {
            if (value && value.length > 0 && !EMAIL_REGEX.test(value)) {
                setError("Invalid email");
                return;
            }
            memberId && updateMemberEmail(memberId, value);
        },
        [updateMemberEmail]
    );

    const isGroupTabAdmin = groupTabOwnerId && members?.length === 1 && members[0].id === groupTabOwnerId;

    const showPartyEmailInvoiceButton = (party: Party, member: PartyMember) => {
        return (
            !!member.id &&
            !!member.email &&
            !party.groupTab &&
            party.payments.some((payment) => payment.memberId === member.id)
        );
    };

    const showGroupTabEmailInvoiceButton = (groupTabOwnerId: string, member: PartyMember) => {
        return !!member.id && !!member.email && member.id === groupTabOwnerId;
    };

    return (
        <Section className="customers">
            <Text preset="header" mode="label" value={isGroupTabAdmin ? "Group Tab admin" : "Customers"} />
            {loading ? (
                <Spinner size="large" />
            ) : !members || members.length === 0 ? (
                <NoData className="customers__empty" />
            ) : (
                <Collapse defaultActiveKey={isGroupTabAdmin || members.length === 1 ? [members[0].id] : undefined}>
                    {members.map((member) => {
                        const isSendingEmail =
                            groupTabBeingSentInvoice ||
                            (!!memberIdBeingSentInvoice && memberIdBeingSentInvoice === member.id);
                        const showPartyMemberEmailInvoice = party && showPartyEmailInvoiceButton(party, member);
                        const showGroupTabEmailInvoice =
                            groupTabId && groupTabOwnerId && showGroupTabEmailInvoiceButton(groupTabOwnerId, member);

                        const handleOnSendInvoice = (event: MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();

                            if (showPartyMemberEmailInvoice) {
                                onPartyMemberEmailInvoiceClick(party.id, member.id);
                            } else if (showGroupTabEmailInvoice) {
                                onGroupTabMemberEmailInvoiceClick(groupTabId, member.id);
                            }
                        };

                        return (
                            <Collapse.Panel
                                key={member.id}
                                header={
                                    <KeyValueRow
                                        className="customer-info-header"
                                        item={{
                                            label: <Text mode="bold" value={member.displayName} />,
                                            value:
                                                showPartyMemberEmailInvoice || showGroupTabEmailInvoice ? (
                                                    <div className="button-email">
                                                        <Button
                                                            size="large"
                                                            type="primary"
                                                            loading={isSendingEmail}
                                                            onClick={handleOnSendInvoice}
                                                        >
                                                            Email invoice
                                                        </Button>
                                                    </div>
                                                ) : undefined,
                                        }}
                                    />
                                }
                            >
                                <div className="customer-info">
                                    <KeyValueRow
                                        item={{
                                            label: "Customer ID",
                                            value: (
                                                <Text mode={["link", "bold"]}>
                                                    <a href={`/${region.getActiveRegion().id}/member/${member.id}/1`}>
                                                        {member.id}
                                                    </a>
                                                </Text>
                                            ),
                                        }}
                                    />
                                    <KeyValueRow
                                        item={{
                                            label: "Phone number",
                                            value: <Text mode="bold">{member.phoneNumber}</Text>,
                                        }}
                                    />
                                    <KeyValueRow
                                        item={{
                                            label: "Email",
                                            value: (
                                                <EditableItem
                                                    error={error}
                                                    disabled={isSendingEmail}
                                                    onUpdate={(update) => onUpdateEmail(member.id, update)}
                                                    defaultValue={member.email}
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            </Collapse.Panel>
                        );
                    })}
                </Collapse>
            )}
        </Section>
    );
};
